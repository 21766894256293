import { createAction } from "@/utils/api";

import { INotify, NotifyType } from "./types";
import { DealingType } from "./types";

export * from './types'

// import './mock'

/**
 * 通知列表
 */
export const loadNotifies = createAction<
  // Response
  {
    entries: Array<INotify>;
    pagination: {
      page: number;
      page_item: number;
      page_total: number;
      total: number;
    }
  },
  {
    type?: NotifyType;
    dealing_type?: DealingType;
    page?: number;
    page_size?: number;
  }
>("GET", "/api/:locale/notify");

/**
 * 單筆通知
 */
export const loadNotify = createAction<
  // Response
  {
    entry: Required<INotify>;
  },
  {
    id: string;
  }
>("GET", "/api/:locale/notify/:id");

/**
 * 未讀通知資訊
 */
export const unreadNotify = createAction<
  {
    entry: {
      count: number
    }
  }
>("GET", "/api/:locale/notify/unread");

/**
 * 已讀通知
 */
export const readNotify = createAction<
  {},
  {
    ids: Array<number>;
  }
>("POST", "/api/:locale/notify/read");
